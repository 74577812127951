import React from "react";
import { getListContentChild } from "../services/api";
import { Link } from "react-router-dom";
import { getDesignById, getFooterSize } from "../services/utils";
import { updateFooterStatus, updateHeaderStatus } from "../actions";
import { connect } from "react-redux";
import JourneyHeader from "./JourneyHeader";

class MosaicJourneyStepList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      isDesktop: window.innerWidth > 768,
      showFooter: null,
    };
  }

  _getDesign(item, only = false, valueOnly = false) {
    let design = getDesignById(this.props.idSection);

    if (!design[item]) {
      return null;
    } // return null if theres no item specified

    if (only) {
      // parameter only means it will only return the property specified
      if (valueOnly) {
        return design[item][only];
      } // parameter valueOnly means it will only return the property value if specified

      design = { [only]: design[item][only] };
      return design;
    }

    return design[item];
  }

  componentDidMount() {
    this._getContent(this.props.data.idContentItem);
    this.setState({
      showFooter:
        this._getDesign(this.props.idSection, "general")?.showBottomMenu ||
        "true",
    });

    window.addEventListener("resize", () =>
      this.setState({ isDesktop: window.innerWidth > 768 })
    );
  }

  _getContent(id) {
    this.setState({ isLoading: true });
    getListContentChild(id)
      .then((res) => {
        if (res.data.success == false) {
          this.setState({ isLoading: false });
          return;
        }
        this.setState({
          isLoading: false,
          data: res.data.listJourneyPart,
        });
      })
      .catch((err) => {
        this.setState({ isLoading: false });
      });
  }

  // window.addEventListener("resize", () => setIsDesktop(window.innerWidth > 768))

  getStylesItem(items, indexItem) {
    if (items === 2) {
      return this.state.isDesktop
        ? {
            width: "50%",
            minWidth: "50%",
            height: `calc(100vh - 54px ${this.state.showFooter && "- 5rem"})`,
          }
        : {
            width: "100%",
            minWidth: "100%",
            height: `calc(50vh - 27px ${this.state.showFooter && "- 2.5rem"})`,
          };
    }
    if (items > 4) {
      if (indexItem === 0) {
        return { minWidth: "66.66%" };
      }
    }
  }

  render() {
    const { updateHeaderStatus, updateFooterStatus } = this.props;
    updateFooterStatus(this._getDesign("general")?.showBottomMenu || "true");
    updateHeaderStatus(this._getDesign("general")?.showTopBar || "true");
    return (
      <section>
        <JourneyHeader data={this.state.data}/>
        <div
          className="container-mosaic"
          style={{ ...this._getDesign("general"), ...getFooterSize() }}
        >
          {this.state.isLoading ? (
            <div className="loader loader-center loader-big"></div>
          ) : (
            this.state.data.map((item, index) => {
              return (
                <Link
                  to={`/content/${item.idContentItem}?idSection=${this.props.idSection}`}
                  className="journey-tile"
                  key={index}
                  style={{
                    height: this.state.isDesktop ? "55vh" : "35vh",
                    ...this.getStylesItem(this.state.data.length, index),
                  }}
                >
                  <div className="gradient-support" />
                  <div className="mosaic-text">
                    <div style={this._getDesign("itemText")}>
                      <span> {item.text} </span>
                    </div>
                    {/* <div style={this._getDesign("itemDescription")}>
                      <span> {item.description} </span>
                    </div> */}
                  </div>
                  <img
                    src={item.image}
                    style={{
                      minWidth: 165,
                      minHeight: 200,
                      backgroundColor: "lightgray",
                    }}
                    alt=""
                  />
                </Link>
              );
            })
          )}
        </div>
      </section>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(MosaicJourneyStepList);
