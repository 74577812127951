import React, { useEffect, useState } from "react";
import { transformText, getSectionById, getDesignById, getColors, getFooterSize, validImage, getDesign} from '../services/utils';
import { withRouter, Link, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';

function _getMenuData(idSection) {
    let data = getSectionById(idSection)
    return data.menuOption
}

// return the main item style if the first is not defined
function getFirstDesign(idSection, firstItem, item){
    if(getDesign(idSection, firstItem) === null){
        return getDesign(idSection, item)
    }else{
        return getDesign(idSection, firstItem)
    }
}

function Mosaic({ idSection, updateFooterStatus, updateHeaderStatus }) {
  const [menuData, setMenuData] = useState(null) 
  const [showFooter] = useState(getDesign(idSection, 'general')?.showBottomMenu || 'true')
  const [ isDesktop, setIsDesktop ] = useState(window.innerWidth > 768)

  window.addEventListener("resize", () => setIsDesktop(window.innerWidth > 768))

  function getStylesItem(items, indexItem) {
    if(items === 2) {
      return isDesktop
      ? { width: '50%', minWidth: '50%', height: `calc(100vh - 54px ${showFooter && '- 5rem'})` }
      : { width: '100%', minWidth: '100%', height: `calc(50vh - 27px ${showFooter && '- 2.5rem'})` } 
    }
    if(items > 4) {
      if(indexItem === 0) {
        return { minWidth: '66.66%' }
      }
    }
  }

    useEffect(() => {
        updateFooterStatus(getDesign(idSection, 'general')?.showBottomMenu || 'true')
        updateHeaderStatus(getDesign(idSection, 'general')?.showTopBar || 'true')
        setMenuData(_getMenuData(idSection))
    }, [])

    return (
        <div className="container-mosaic" style={ {...getDesign(idSection, 'general'), ...getFooterSize()} }>
            {
                menuData && menuData.map((item, index) => {

                    const divStyleArray = {
                        icon: {
                            border: `3px solid ${getDesign(idSection, 'general', 'backgroundColor',true)}`,
                            backgroundColor: item.color,
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        },
                        hybrid: {
                            border: `3px solid ${getDesign(idSection, 'general', 'backgroundColor',true)}`,
                            backgroundColor: item.color,
                            height: isDesktop ? '55vh' : '35vh',
                        },
                        tile: {
                            border: `3px solid ${getDesign(idSection, 'general', 'backgroundColor',true)}`,
                            height: isDesktop ? '55vh' : '35vh',  ...getStylesItem(menuData.length, index)
                        },
                    }
                
                    const linkStyle = divStyleArray[item.imageStyle] || {}

                    return (
                        // icon imageStyle || tite
                        <Link 
                          to={`/${transformText(item.text)}/${item.sectionTo}?idSection=${item.sectionTo}`} 
                          className="tile" key={index} 
                          style={linkStyle} 
                        >
                            <div className="gradient-support" 
                                style={{
                                    backgroundImage: ((item.imageStyle === 'tile' || item.imageStyle === 'hybrid') && validImage(item.imageStyle)) ? `url(${item.image})` : '',
                                    backgroundSize:'cover',
                                    backgroundRepeat:'no-repeat',
                                    backgroundPosition:'center',
                                    display: "flex",
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}

                            >
                                {
                                    item.showText === 1 &&
                                    <div className="mosaic-text">
                                        <div style={ index === 0 ? getFirstDesign(idSection, 'firstItemText', 'itemText') :  getDesign(idSection, 'itemText') } >
                                            <span> {item.text} </span>
                                        </div>
                                        <div style={ index === 0 ? getDesign(idSection, 'firstItemDescription') :  getDesign(idSection, 'itemDescription') }>
                                            <span> { (item.description && item.description !== 'null') && item.description }</span>
                                        </div>
                                    </div>
                                }

                                {
                                    (item.imageStyle === 'hybrid' && validImage(item.imageSecondary)) && 
                                    <img src={item.imageSecondary} style={{alignSelf: 'center', width: 'auto', height: 'auto'}}  alt=""/>
                                }

{
                                    (item.imageStyle === 'icon' && validImage(item.image)) && 
                                    <img src={item.image} style={{maxHeight: 56, maxWidth: 57, alignSelf: 'center', width: 'auto', height: 'auto'}}  alt=""/>
                                }


                            </div>
                           
                        </Link>
                    )
                })
            }

        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
    updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
  });
  
export default connect(null, mapDispatchToProps)(Mosaic);
