import React from 'react';
import { findContent } from '../../services/api';
import moment from 'moment';
import Rate from '../Rate';

import {
	getFooterSize,
	getDesignById,
	getColors,
	getGamigication,
} from '../../services/utils';
import {
	updateFooterStatus,
	updateHeaderStatus,
	updateScorePoints,
} from '../../actions';
import { connect } from 'react-redux';
import CommentsChat from '../CommentsChat';

import { getAllMenuOptions, getPathBySectionId } from '../../services/utils';

let idContent;
let timeStart;

class ContentPost extends React.Component {
	constructor(props) {
		super(props);
        this._isMounted = true;
		this.state = {
			isLoading: false,
			data: [],
			points: false,
		};
	}

	_getSectionPath() {
		let idSection = this.props.idSection;
		let path;

		if (window.location.hash.includes('/content/')) {
			path = getPathBySectionId(idSection);
		} else {
			const allMenu = getAllMenuOptions();
			const parentIdSection = allMenu.find(
				(e) => e.sectionTo === parseInt(idSection)
			)?.parentIdSection;
			path = getPathBySectionId(parentIdSection);
		}
		return path;
	}

	componentDidMount() {
		this._getContent(this.props.data.idContentItem);
		const { updateHeaderStatus, updateFooterStatus } = this.props;
		updateFooterStatus(this._getDesign('general')?.showBottomMenu || 'true');
		updateHeaderStatus(this._getDesign('general')?.showTopBar || 'true');
		let gamification = this._getGamification();
		if (gamification) {
			this.setPoints();
		}
	}

    componentWillUnmount(){
        this._isMounted = false;
    }

	_getDesign(item, only = false, valueOnly = false) {
		let design = getDesignById(this.props.idSection);

		if (!design[item]) {
			return null;
		} // return null if theres no item specified

		if (only) {
			// parameter only means it will only return the property specified
			if (valueOnly) {
				return design[item][only];
			} // parameter valueOnly means it will only return the property value if specified

			design = { [only]: design[item][only] };
			return design;
		}

		return design[item];
	}
	_getGamification() {
		return getGamigication();
	}

	async _getContent(id) {
		const result = await findContent(id);
		if (this._isMounted) {
			try {
				if (!result.data.success) {
					this.setState({ isLoading: false });
					return;
				}
				this.setState({
					isLoading: false,
					data: result.data.content,
				});
				//inicia o trackig
				id = result.data.content[0].idContentItem;
				idContent = id;
				timeStart = moment().utc().format('YYYY-MM-DD HH:mm:ss');
			} catch (err) {
				this.setState({ isLoading: false });
			}
		}
	}
	setPoints = () => {
		if (!this.state.points) {
			this.props.updateScorePoints(50);
			this.setState({ points: true });
		}
	};

	render() {
		
		let gamification = this._getGamification();

		return (
			<div className="content-post-container" style={{ ...getFooterSize() }}>
				{this.isLoading ? (
					<div className="loader loader-center loader-big loader-purple"></div>
				) : (
					this.state.data.map((item, index) => {
						return (
							<div key={index}>
								<div
									style={{ color: '#4B4057', fontSize: 24, fontWeight: 500 }}
								>
									{item.text.toUpperCase()}
								</div>
								<div
									style={{
										padding: '10px 15px',
										marginLeft: '-8%',
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<Rate
										stars={item.rating}
										id={this.props.data.idContentItem}
										rateContainer={{ padding: 0 }}
										numberVotes={item.numberVotes}
										click={true}
										avalie={'avalie este conteúdo'}
										styleStarsAvalie={{ color: '#666666' }}
									/>
									{gamification && (
										<div className="points">
											<span className="number">{50}</span>
											<span> pts</span>
										</div>
									)}
								</div>
								<img
									src={`${item.image}`}
									style={{ width: '100%', marginTop: 10, marginBottom: 10 }}
									alt="img"
								/>
								<div style={{ whiteSpace: 'pre-wrap' }}>
									{item.content?.replace('<br>', '\n\n')}
								</div>

								<div style={{ marginTop: 15 }}>
									{item.referenceLink && item.referenceLink.length > 0 && (
										<span
											onClick={() =>
												window.open(`${item.referenceLink}`, '_blank')
											}
											className="saiba-mais"
											style={{ backgroundColor: getColors('menu').firstColor }}
										>
											saiba mais
										</span>
									)}
								</div>
								{this.state.data[0].idChatRoom && (
									<CommentsChat room={this.state.data[0].idChatRoom} />
								)}
							</div>
						);
					})
				)}
			</div>
		);
	}
}

const mapDispatchToProps = (dispatch) => ({
	updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
	updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
	updateScorePoints: (item) => dispatch(updateScorePoints(item)),
});

export default connect(null, mapDispatchToProps)(ContentPost);
