import React, { useState, useEffect } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import '../index.css'
import HomeMenu from './HomeMenu';
import { getSectionByType, getColors } from '../services/utils';
import { contentList } from '../services/api';
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getMenuData, setLoginData, setUserAgreement, getToken, logout } from '../services/api';
import jwtDecode from 'jwt-decode';
import { compose } from 'redux';
import { updateFooterStatus, updateHeaderStatus } from '../actions';
import { connect } from 'react-redux';
import ModalWarning from './ModalWarning';
import ContentIcon from '../assets/images/content-icon.svg';
import OnBoard from "./OnBoard";
import AskPWA from './askPWA';
import Loader from './Loader';

const TelaPrincipal = (props) => {

  const [showModal, setShowModal] = useState(false)
  const [showOnBoard, setShowOnBoard] = useState(false)
  const [onBoardUrl, setOnBoardUrl] = useState(null)
  const [communicationAccepted, setCommunicationAccepted] = useState(false)
  const [showError, setShowError] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [communication, setCommunication] = useState(false)
  const [showLastContentModal, setShowLastContentModal] = useState(false)
  const [homeTypeState, setHomeTypeState] = useState(null)
  const [homeContent, setHomeContent] = useState(null)
  const [redirectContent, setRedirectContent] = useState(false)
  const [smsNotification, setSmsNotification] = useState(false)
  const [emailNotification, setEmailNotification] = useState(false)
  const [lastTrackingState, setLastTrackingState] = useState(null)
  const [termos, setTermos] = useState(false)
  const [isLoadingHome, setIsLoadingHome] = useState(true)

  function getInfo() {
    let menuData = getMenuData();
    if (!menuData.loginInfo) { return }
    if (menuData.loginInfo.communicationAccepted === 1) {
      setCommunicationAccepted(true)
      setCommunication(true)
    } else {
      setCommunicationAccepted(false)
    }

  }

  function getProfileInfo() {
    let token = {}
    try {
      token = jwtDecode(getToken())
    } catch {
      console.log('error on token get')
    }
    return token
  }

  function showTerms() {
    let loginInfo = getMenuData().loginInfo
    if (!loginInfo) { return }
    if (loginInfo.userLicenceTermsAccepted !== loginInfo.agreementVersion) {
      setShowModal(true)
    }
  }

  function _setShowOnBoard() {
    let loginInfo = getMenuData().loginInfo

    if (loginInfo) {
      const onBoardScreen = loginInfo.onBoardScreen
      const urlOnBoard = loginInfo.onBoardUrl
      if (onBoardScreen && urlOnBoard) {
        setShowOnBoard(true)
        setOnBoardUrl(urlOnBoard)
      }
    }
  }

  function showPWA() {
    let loginInfo = getMenuData()?.loginInfo
    if (loginInfo?.askPWA !== 0) {
      return true
    }
    return false
  }

  function updateTerms() {
    let menuData = getMenuData()
    menuData.loginInfo.userLicenceTermsAccepted = menuData.loginInfo.agreementVersion
    let user = getProfileInfo()
    let data = {
      "communicationAccepted": communication ? 1 : 0,
      "userLicenceTermsAccepted": menuData.loginInfo.agreementVersion,
      // "smsNotification": smsNotification ? 1 : 0,
      // "emailNotification": emailNotification ? 1 : 0,
    }
    setLoginData(menuData)
    setUserAgreement(user.idUser, data)
  }

  function getTerms() {
    let terms = ''

    try {
      terms = getMenuData().loginInfo.agreementTerms
    }
    catch {
      return ''
    }
    return terms
  }

  function getLastContent() {
    const fromLogin = props?.location?.state?.referrer
    const lastTracking = props?.location?.state?.lastTracking
    const lastContentRedirect = props?.location?.state?.lastContentRedirect
    if (fromLogin && lastTracking && lastContentRedirect) {
      setShowLastContentModal(true)
      setLastTrackingState(lastTracking)
    }
  }

  function redirectToContent() {
    setRedirectContent(true)
  }

  useEffect(() => {
    const { updateFooterStatus, updateHeaderStatus } = props
    updateFooterStatus(getColors('footer').showfooter)
    updateHeaderStatus(getColors('general')?.showTopBar || 'true')
    getInfo()
    showTerms()
    getInfoHomeMenu()
    getLastContent()
  }, [])

  function _logout() {
    logout()
    setRedirect(true)
  }

  function continuar() {
    if (!termos) {
      setShowError(true)
      return
    }
    updateTerms()
    _setShowOnBoard()
    setShowModal(false)
  }

  function getFooterSize() {
    if (getColors('footer').showfooter === 'true' || getColors('footer').showfooter === true) {
      return { paddingBottom: '5rem' }
    } else {
      return {}
    }
  }

  async function getInfoHomeMenu() {
    const { idSection } = getSectionByType(1)
    const { homeType } = getColors('home')
    contentList(idSection).then(res => {
      setHomeTypeState(homeType)
      setHomeContent(res?.data?.content)
    }).finally(() => {
      setIsLoadingHome(false)
    })
  }

  function getHomeMenu() {
    if(isLoadingHome){
      return <Loader />
    }else{
      return <HomeMenu type={homeTypeState} content={homeContent} />
    }
  }

  function toogleNotificationOption(type, bol) {
    if(type === 'email') {
      setSmsNotification(!bol)
      setEmailNotification(bol)
    } else {
      setEmailNotification(!bol)
      setSmsNotification(bol)
    }
  }

    if (redirect) {
      return <Redirect to='/login' />;
    }
    if (redirectContent) {
      return <Redirect to={`/content/${lastTrackingState?.idContentItem}?idSection=${lastTrackingState?.idSection}`} />;
    }

    return (
      <div>
        <div className="tela-principal-container" style={{ backgroundPosition: '0 0', ...getFooterSize() }} role="main">
          { showOnBoard && <OnBoard url={onBoardUrl} /> }
          {
            !showModal && showPWA() && (
              <AskPWA />
            )
          }

          {
            showLastContentModal &&
            !showModal && (
              <ModalWarning
                acceptText="Ir para conteúdo"
                denyText="Ir para tela inicial"
                title={'Retornar ao seu ultimo conteúdo acessado?'}
                acceptAction={() => redirectToContent()}
                icon={
                  <
                    img src={ContentIcon}
                    style={{ height: 75, width: 75, opacity: 0.8 }}
                    alt=""
                  />
                }
              />
            )
          }
          {getHomeMenu()}
          <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            backdrop="static"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            size="lg"
            className="modal-main-container"
          >
            <div className="modal-support-blur" />
            <Modal.Header>
              <Modal.Title>Termo de aceite</Modal.Title>
            </Modal.Header>
            <div className="modal-principal">
              <div className="modal-body-term-container">
                <div dangerouslySetInnerHTML={{ __html: getTerms() }} />
              </div>
              <div style={{ marginTop: 15, fontWeight: 500 }}>
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check onChange={(e) => setTermos(e.target.checked) } type="checkbox" label="Li e aceito os termos listados acima." />
                  {showError && <div className="term-error"> Você precisa aceitar os termos para continuar. </div>}
                </Form.Group>
                {
                  communicationAccepted &&
                  <div>
                    <Form.Group controlId="formBasicCheckbox2" style={{marginBottom: 0}}>
                      <Form.Check  onChange={(e) => setCommunication(e.target.checked)} type="checkbox" label="Aceito receber e-mail ou outras comunicações." checked={communication} />
                    </Form.Group>
                    {/* {
                      communication &&
                      <div>
                        <Form.Group controlId="formBasicCheckbox3" style={{marginBottom: 0}}>
                          <Form.Check onClick={(e) => toogleNotificationOption('email', e.target.checked)} type="radio" label="E-mail" checked={emailNotification} />
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox4" style={{marginBottom: 0}}>
                          <Form.Check onClick={(e) => toogleNotificationOption('sms', e.target.checked)} type="radio" label="SMS" checked={smsNotification} />
                        </Form.Group>
                      </div>
                    } */}
                  </div>
                }

              </div>
            </div  >
            <Modal.Footer>
              <Button variant="secondary" onClick={() => _logout()}>
                Sair
              </Button>
              <Button className="btn botao-login" style={{ backgroundColor: getColors('menu').secondColor }} onClick={() => continuar()}>
                Continuar
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    )
  }


const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});
export default compose(
  withRouter,
  connect(null, mapDispatchToProps)
)(TelaPrincipal);