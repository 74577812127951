import React from "react";
import { BsArrowLeftShort } from "react-icons/bs";
import JourneyRatingCard from "./JourneyRatingCard";
import { useHistory } from "react-router-dom";

const JourneyHeader = ({ data, index }) => {
  let showRatingCard = false;
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  return (
    <div>
      <div className="journey-header-header">
        <div>
          <button className="back-to-journeys-button" onClick={goBack}>
            <BsArrowLeftShort size={30} color="grey" />
          </button>
        </div>
        <div>
          <label className="journey-title-label">{data[index]?.parentName}</label>
        </div>
      </div>
      {showRatingCard && <JourneyRatingCard />}
    </div>
  );
};

export default JourneyHeader;
