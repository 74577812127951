import React from "react";
import { contentList } from '../../services/api';
import { getDesign, getFooterSize } from '../../services/utils';
import { updateFooterStatus, updateHeaderStatus } from '../../actions';
import { connect } from 'react-redux';
import Card from './components/Card'


class MosaicSiteList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        isLoading: false,
        data: [],
        isDesktop: window.innerWidth > 768,
        showFooter: null
    };
}

componentDidMount(){
  this._getContent(this.props.idSection)
  this.setState({ showFooter: getDesign(this.props.idSection, 'general')?.showBottomMenu || 'true'})

  window.addEventListener("resize", () => this.setState({ isDesktop: window.innerWidth > 768 }))
}

_getContent(id){
    this.setState({ isLoading: true })
    contentList(id).then(res => {
        if(res.data.success == false){
            this.setState({ isLoading: false })
            return
        }
        this.setState({ 
            isLoading: false,
            data: res.data.content
        })
    }).catch(err => {
        this.setState({ isLoading: false })
    })
}

  getStylesItem(items, indexItem) {
    if(items === 2) {
      return this.state.isDesktop
      ? { width: '50%', minWidth: '50%', height: `calc(100vh - 54px ${this.state.showFooter && '- 5rem'})` }
      : { width: '100%', minWidth: '100%', height: `calc(50vh - 27px ${this.state.showFooter && '- 2.5rem'})` } 
    }
    if(items > 4) {
      if(indexItem === 0) {
        return { minWidth: '66.66%' }
      }
    }
  }

    render(){
        const { updateHeaderStatus, updateFooterStatus } = this.props
        updateFooterStatus( getDesign(this.state.idSection,'general')?.showBottomMenu || 'true')
        updateHeaderStatus( getDesign(this.state.idSection,'general')?.showTopBar || 'true')
        return (
            <div className="container-mosaic"  style={ { ...getDesign(this.state.idSection,'general'), ...getFooterSize()} }>
                {
                    this.state.isLoading ?
                        <div className="loader loader-center loader-big"></div>
                    :
                        this.state.data.map((item, index) => {
                        return (
                            <Card 
                                item={item} 
                                isDesktop={this.state.isDesktop} 
                                dataLength={this.state.data.length} 
                                idSection={this.props.idSection} 
                                index={index}
                                showFooter={this.state.showFooter}
                                key={index}
                            />
                        )
                    })
                }
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(MosaicSiteList);
