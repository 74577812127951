import React from "react";
import CompleteRating from "../CompleteRating";
import "../../index.css";
import "../../assets/css/ModalPlayer.css";
import AudioPlayer from "react-h5-audio-player";
import moment from "moment";
import { getColors, getDesign, getFooterSize } from "../../services/utils";
import { updateFooterStatus, updateHeaderStatus } from "../../actions";
import { connect } from "react-redux";
import style from './style.module.scss';
import PodcastList from "../PodcastList";
import { recordTracking } from '../../services/api';
import ModalContinueMedia from '../ModalContinueMedia';
import AudioInfo from "./Component/AudioInfo";
import AudioControl from "./Component/AudioControl";

class ContentAudio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      key: 0,
      descriptiion: "",
      image: "",
      contentLocation: "",
      url: null,
      playing: false,
      controls: false,
      played: 0,
      duration: 0,
      isDesktop: false,
      idContentItem: 0,
      timeStart: moment().utc().format("YYYY-MM-DD HH:mm:ss"),
      isLoadingData: true,
      noData: false,
      lastTime: 0,
      arrayTime: [],
      timeExecuted: 0,
      modal: false,
    };
    this.audioplay = React.createRef();
  }

  startFromBeggining(){
    this.audioplay.current.audio.current.currentTime = 0;
    this.audioplay.current.audio.current.play();
    this.setState({ modal: false });
  }

  continueFromLastTime(){
    this.audioplay.current.audio.current.play();
    this.setState({ modal: false });
  }

  currentTime = () => {
    return this.audioplay.current.audio.current.currentTime;
  }

  handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  handlePlayPause = (e) => {
    e.preventDefault();
    const audio = this.audioplay.current.audio.current;
    if (!this.state.playing) {
      this.setState({ playing: true });
      audio.play();
      trackStart(this);
    } else {
      this.setState({ playing: false });
      audio.pause();
    }
  };

  handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!this.state.seeking) {
      this.setState(state);
    }
  };

  handleDuration = (duration) => {
    this.setState({ duration });
  };

  handleEnded = () => {
    this.setState({ playing: this.state.loop });
    this.props.handler[1](this.state.key);
  };

  handlePause = () => {
    this.setState({ playing: false });
  };

  handlePlay = () => {
    this.setState({ playing: true });
    trackStart(this);
  };

  handleDuration = (duration) => {
    this.setState({ duration });
  };

  componentDidMount() {
    if( !(this.props.data.lastPosition === null || this.props.data.lastPosition === 0) ){
      this.audioplay.current.audio.current.currentTime = this.props.data.lastPosition;
      this.setState({ modal: true });
    }
    
    this.audioplay.current.progressBar.current.childNodes[0].style.backgroundColor =
      getDesign(this.props.idSection, "itemText")?.color;
    this.audioplay.current.progressBar.current.childNodes[0].childNodes[0].style.cssText += ` background: ${
      getDesign(this.props.idSection, "itemText")?.color
    } !important;`;
    this.audioplay.current.progressBar.current.nextElementSibling.style.cssText += ` color: ${
      getDesign(this.props.idSection, "itemText")?.color
    } !important;`;
  }

  componentWillUnmount() {
    // id contentType existe somente 2 (valores 1: conteudo sozinho, valor 2: video/audio dentro do html(frame))

    this.exit()

  }

  exit() {
    const timeStart = this.state.timeStart;
    const navItem = this.props.data.idContentItem;
    const navType = 1
    const idSection = this.props.idSection;
    const timeExecuted = this.currentTime();

    recordTracking(timeStart, navItem, navType, idSection, timeExecuted)
  }

  render() {
    const { playing, isDesktop } = this.state;
    const { data } = this.props;
    updateFooterStatus(getDesign(this.props.idSection, "general")?.showBottomMenu || "true");
    updateHeaderStatus(getDesign(this.props.idSection, "general")?.showTopBar || "true");

    const rangeWidth = {width: isDesktop ? "50%" : "100%"}


    return (
      <>
        <ModalContinueMedia 
          startMedia={() => { this.startFromBeggining() }} 
          continueMedia={() => { this.continueFromLastTime() }} 
          mediaType='audio' 
          idParentSection={this.props.idSection}
          show={this.state.modal}
        />
        <div className={style.wrapper} style={{ ...getFooterSize(), ...getDesign(this.props.idSection, "general") }}>
          {
            <div className={style.pageWrapper} >
              <div className={style.bodyContent}>
                <div className={style.bodyMedia}>
                  <img className={style.podcastImg} src={data.image} data-backdrop="false" style={{ width: "300px" }} alt="img" />
                </div>
                <div className={style.audioModule}>
                  <AudioInfo idSection={this.props.idSection} isDesktop={isDesktop} title={data.text} author={data.author}/>
                  <div className={style.bodyRange}>
                    <AudioPlayer
                      autoPlay={ !this.state.modal }
                      ref={this.audioplay}
                      loop={false}
                      style={{
                        ...rangeWidth,
                        backgroundColor: "transparent",
                        color: "white",
                        position: "relative",
                      }}
                      src={data.contentLocation}
                      layout={"horizontal-reverse"}
                      showSkipControls={false}
                      showJumpControls={false}
                      showFilledProgress={false}
                      onPlay={this.handlePlay}
                      onPause={this.handlePause}
                      onProgress={this.handleProgress}
                      onDuration={this.handleDuration}
                      onEnded={this.exit}
                    />
                  </div>
                  <AudioControl idSection={this.props.idSection} playing={playing} handlePlayPause={this.handlePlayPause} />
                  <div
                    className="modal-player-rating"
                    style={{
                      ...getFooterSize(),
                      ...getDesign(this.props.idSection, "itemText"),
                      padding: 0,
                    }}
                  >
                    <CompleteRating stars={this.props.data.rating} numberVotes={data.numberVotes} />
                    <CompleteRating id={data.idContentItem} userStar={data.vote} />
                  </div>
                </div>
                
              </div>
              <div className={style.podCastList}>
                <div className={style.podcastImgTitle} style={getDesign(this.props.idSection, "itemText",'fontFamily')} >
                  Ouça também
                </div>
                <PodcastList idSection={this.props.idSection} />
              </div>
            </div>
          }
        </div>
      </>
    );
  }
}

function trackStart(parameters) {
  parameters.state.timeStart = moment().utc().format("YYYY-MM-DD HH:mm:ss");
}

const mapDispatchToProps = (dispatch) => ({
  updateFooterStatus: (item) => dispatch(updateFooterStatus(item)),
  updateHeaderStatus: (item) => dispatch(updateHeaderStatus(item)),
});

export default connect(null, mapDispatchToProps)(ContentAudio);