import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import api from "../services/api";
import { getColors, getDesign, getFooterSize } from "../services/utils";
import "../assets/css/MultipleFormatList.scss";
import { Icon } from "@iconify/react";
import playCircle from "@iconify/icons-mdi/play-circle";
import filmstripIcon from "@iconify/icons-mdi/filmstrip";
import pageNext from "@iconify/icons-mdi/page-next";
import pageLayoutHeader from "@iconify/icons-mdi/page-layout-header";
import arrowExpand from "@iconify/icons-mdi/arrow-expand";

function MultipleFormatList({ idSection }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorOnLoading, setErrorOnLoading] = useState(false);

  const getMultipleFormatList = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/content/list/${idSection}`);
      setData(data.content);
    } catch (err) {
      console.log("Error:", err);
      setErrorOnLoading(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getMultipleFormatList();
  }, []);

  const SwitchIcon = ({ type }) => {
    let icon = null,
      text = "";

    switch (type) {
      case 1:
        icon = playCircle;
        text = "Ouvir";
        break;
      case 2:
        icon = filmstripIcon;
        text = "Assistir";
        break;
      case 3:
        icon = pageLayoutHeader;
        text = "Abrir";
        break;
      case 4:
        icon = pageNext;
        text = "Abrir";
        break;
      case 5:
        icon = pageLayoutHeader;
        text = "Abrir";
        break;

      default:
        icon = arrowExpand;
        text = "Abrir";
        break;
    }

    return (
      <>
        <Icon className="icon" icon={icon} />
        &nbsp;
        <span>{text}</span>
      </>
    );
  };

  return (
    <div
      className="multiple-format-list"
      style={{ ...getDesign(idSection, "general"), ...getFooterSize() }}
    >
      {loading ? (
        <Loader />
      ) : errorOnLoading ? (
        <div className="multiple-format-list-error">
          Houve algum erro durante o carregamento das informações
        </div>
      ) : (
        <ul className="multiple-format-list__content">
          {data.length > 0 ? (
            data.map((item, index) => (
              <li className="multiple-format-list__item" key={index}>
                <Link
                  className="multiple-format-list__item--link"
                  to={`/content/${item.idContentItem}?idSection=${idSection}`}
                  replace
                >
                  <div className="multiple-format-list__item--image">
                    <img src={item.image} alt="" />
                  </div>

                  <div className="multiple-format-list__item--info">
                    <h3
                      className="title"
                      style={getDesign(idSection, "itemText")}
                    >
                      {item.text}
                    </h3>
                    <p
                      className="author"
                      style={getDesign(idSection, "itemDescription")}
                    >
                      <b>Autor:</b> {item.author}
                    </p>
                    <div
                      className="type-icon"
                      style={{
                        color:
                          getDesign("itemText")?.color ??
                          getColors("menu").fourthColor,
                      }}
                    >
                      <SwitchIcon type={item.idContentType} />
                    </div>
                  </div>
                </Link>
              </li>
            ))
          ) : (
            <div className="no-content">Nenhum conteúdo disponível</div>
          )}
        </ul>
      )}
    </div>
  );
}

export default MultipleFormatList;
