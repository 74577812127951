import React, { useState, useEffect } from "react";
import JourneyHeader from "./JourneyHeader";
import JourneyPageRendering from "./JourneyPageRendering";
import { BsChevronLeft, BsChevronRight, BsGeoAltFill } from "react-icons/bs";
import JourneyStepSideMenu from "./JourneyStepSideMenu";
import { getListContentChild } from "../services/api";
import { getFooterSize } from "../services/utils";

const JourneyStepNavigation = (props) => {
  const [displaySideMenuState, setDisplaySideMenu] = useState(false);
  const [pages, setPages] = useState([]);
  const [index, setIndex] = useState(0);

  getListContentChild(props.data.idContentItem)
    .then((res) => {
      if (!res.data.success) {
        return;
      }
      return res.data.listJourneyPart;
    })
    .catch((err) => {
      console.log(err);
    });

  useEffect(() => {
    getListContentChild(props.data.idContentItem)
      .then((res) => {
        if (!res.data.success) {
          return;
        }
        let array = [];
        array = res.data.listJourneyPart.map((page) => {
          return page;
        });
        setPages(array);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const changePage = (newIndex) => {
    if (newIndex >= 0 && newIndex < pages.length) {
      setIndex(newIndex);
    }
  };

  return (
    <div className="step-main-container">
      <JourneyHeader data={pages} index={index} />
      <div className="step-progress-bar">
        <div
          className="step-progress"
          style={{
            width: ((index + 1) / pages.length) * 100 + "%",
          }}
        ></div>
      </div>
      <JourneyStepSideMenu
        display={displaySideMenuState}
        pages={pages}
        index={index}
        pageClickHandler={(newIndex) => {
          changePage(newIndex);
        }}
        closeButtonHandler={() => {
          setDisplaySideMenu(false);
        }}
      />
      {pages.length > 0 && (
        <JourneyPageRendering page={pages[index]?.contentLocation} />
      )}
      <div className="step-footer">
        <button
          className="step-footer-button"
          onClick={() => changePage(index - 1)}
        >
          <BsChevronLeft size={20} className="" />
        </button>
        <button
          className="step-footer-button"
          onClick={() => setDisplaySideMenu(!displaySideMenuState)}
        >
          <BsGeoAltFill size={23} className="" />
        </button>
        <button
          className="step-footer-button"
          onClick={() => changePage(index + 1)}
        >
          <BsChevronRight size={20} className="" />
        </button>
      </div>
    </div>
  );
};

export default JourneyStepNavigation;
