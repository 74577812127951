import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { getDesign } from '../../../../services/utils'
import Rate from '../../../../components/Rate'
import ProgressBar from '../../../../components/ProgressBar'
import styles from "./styles.module.scss"

const Card = ({item, isDesktop, dataLength, idSection, index, showFooter}) => {

    const getStylesItem = (itemsQtd, indexItem) => {
        if(itemsQtd === 2) {
            return isDesktop
            ? { width: '50%', minWidth: '50%', height: `calc(100vh - 54px ${showFooter && '- 5rem'})` }
            : { width: '100%', minWidth: '100%', height: `calc(50vh - 27px ${showFooter && '- 2.5rem'})` } 
        }
        if(itemsQtd > 4) {
            if(indexItem === 0) {
                return { minWidth: '66.66%' }
            }
        }
    }

    useEffect(() => {
        console.log('rating', item.rating, item)
    },[item])
   
    return (
        <div 
            className="tile"
            style={{ height: isDesktop ? '55vh' : '35vh', ...getStylesItem(dataLength, index) }}  
        >
            <Link 
                to={`/content/${item.idContentItem}?idSection=${idSection}`} 
            >
                <div className="gradient-support" />
                <img src={item.image} style={{ minWidth: 165, minHeight: 200, backgroundColor: 'lightgray' }} alt="" />
            </Link>
            <div className={styles.mosaicText}>
                    <Link 
                        to={`/content/${item.idContentItem}?idSection=${idSection}`} 
                    >
                        <div style={ getDesign(idSection, 'itemText') }>
                            <span> {item.text} </span>
                        </div>
                    </Link>
                    <div style={{ display: "block", ...getDesign(idSection,'itemText','textAlign') }}>
                        <Rate 
                            stars={item.rating}
                            id={item.id} 
                            numberVotes={item.numberVotes} 
                            avalie={false} 
                            click={false} 
                            classNameContainer={styles.rateContainer}
                            classNameRatingContainer={styles.classNameRatingContainer}
                            rateContainer={{padding: "0px"}}
                            
                            /*
                            styleStarsAvalie=null, 
                            rateAction={}, 
                            styleFeedBack={}, 
                            */
                        />
                    </div>
                    {
                        item?.progress ? (
                            <div style={{ display: "block", ...getDesign(idSection,'itemText','textAlign') }}>
                                <ProgressBar percent={item?.progress} completed={item?.completed} legend={false}/>
                            </div>
                        ) : null

                    }
                    <div style={ getDesign(idSection,'itemDescription') }>
                        <span> {item.description} </span>
                    </div>
                </div>
        </div>
    )
}

export default Card;