import React from 'react'
import style from './style.module.scss'
import { getDesign } from '../../../../services/utils'
import { ReactComponent as PlayIcon } from "../../../../assets/images/play.svg";
import { ReactComponent as PauseIcon } from "../../../../assets/images/pause.svg";

const AudioControl = ({idSection, playing, handlePlayPause}) => {
    
    const fillColorStyle = {fill: getDesign(idSection, "itemText")?.color};

    return (
        <div className="body-media-control" style={{ display: "flex" }}>
            <span className="status-player icon-play">
                {playing ? (
                <PauseIcon className={style.iconButton} style={fillColorStyle} onClick={handlePlayPause} />
                ) : (
                <PlayIcon className={style.iconButton} style={fillColorStyle} onClick={handlePlayPause} />
                )}
            </span>
        </div>
    )
}

export default AudioControl;