import React from 'react';
import style from './styles.module.scss';
import { FaCheck } from 'react-icons/fa';


const ProgressBar = ({ percent, completed, legend = true, classNameContainer="" }) => {

    const isCompleted = Boolean(completed);

    const Bar = (
        <div className={style.backgroundBar}>
            <div style={{width: `${percent}%`}} className={style.progressBar}>
                { legend === true && <><span className={style.progressBarPercent}>{percent}%</span>&nbsp;</> } 
            </div>
        </div>
    )

    const Concluido = (
        <span className={style.concluido}>
            <FaCheck /> Concluído
        </span>
    );

    return (
        <div className={style.wrapper + ' ' +classNameContainer+ ' progressBarContainer'}>
            { (isCompleted) ? Concluido : Bar }
        </div>
    )
}

export default ProgressBar;