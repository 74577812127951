import React, { Component } from 'react';
import { chatListRooms } from '../../services/api';
import './ChatRoom.css';
import './ChatRoomsList.css';
import { getColors } from '../../services/utils';
import { NavLink } from "react-router-dom";
import BackLogo from '../../assets/images/back-button.png';
import Loader from '../../components/LoaderWhite'

class ChatRoomsList extends Component {

    constructor(props) {
        super(props)

        this.state = {
            currentRoom: "",
            roomsList: [],
            isLoading: true,
            error: false
        }

    }

    _chatListRooms() {
        chatListRooms().then(res => {
            if (res.data.success == false) {
                this.setState({ error: true })
                return
            }
            this.setState({ roomsList: res.data.chatrooms });
        }).catch(err => {
            this.setState({ error: true })
        }).finally(() => {
            this.setState({ isLoading: false })
        })
    }

    componentDidMount() {
        this._chatListRooms();
    }


    render() {
        let vh = window.innerHeight * 0.01;
        let rooms;

        rooms = this.state.roomsList.map((m, k) => (
            <NavLink to={"/chat/" + (m.idChatRoom)} className="menu-secondary-left-item" key={k}>
                {console.log(m)}
                <li className="chat-list-item" onClick={() => { this.props.closeChat(); this.props.expand() }}>
                {m.roomName}
                </li>
            </NavLink>
        ));

        return (
            <div className="chat-room-container" style={{ backgroundColor: getColors('menu').fourthColor, width: "100%" }}>
                <div className="chat-room-msg" style={{ backgroundColor: getColors('menu').firstColor }}>
                    <div className="chat-close" onClick={() => { this.props.closeChat() }}>
                        <img src={BackLogo} style={{ height: 30, marginRight: 10 }} alt="" />
                    </div>
                    <h1 className="title">Salas</h1>
                    <div className="chat-room-list">
                        {
                            this.state.isLoading ? (
                                <Loader customStyle={{ height: 150 }} />
                            ) : (
                                <ul style={{ width: '100%' }}>
                                    {rooms}
                                </ul>
                            )
                        }
                    </div>
                </div >
            </div>
        )

    }
}

export default ChatRoomsList;