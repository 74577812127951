import { colors } from '@material-ui/core';
import React from 'react';
import downloadIcon from '../../assets/images/download-icon.svg';
import { getColors, getDesign } from '../../services/utils';
import styles from './styles.module.scss'

const FileDetail = ({data, key, idSection}) => {

    return (
        <div className={styles.contentWrapper}>
            <div className={styles.fileWrapper}>
                <i className={styles.generic}></i>
                <div className={styles.text} style={{ ...getDesign(idSection,"itemText"), color: '#000', textAlign: 'left'}}>
                    {data.text}
                </div>
                {
                    data.author && (
                        <div className={styles.author} style={{ ...getDesign(idSection,"itemDescription"), color: '#000'}}>
                            por {data.author}
                        </div>
                    )
                }
                <div className={styles.description} style={{ ...getDesign(idSection,"itemDescription"), color: '#000', textAlign: 'left'}}>
                    {data.description}
                </div>
                <div className={styles.message} style={{ ...getDesign(idSection,"itemDescription"), color: '#000'}}>
                    Para baixar o arquivo anexado ao post clique no botão abaixo.
                </div>
                <a href={data.contentLocation} className={styles.link} 
                    style={{ backgroundColor: getColors('menu').firstColor }} download target="_blank">
                    <img width="16" src={downloadIcon} alt="" />
                    DOWNLOAD
                </a>
            </div>
        </div>
    )

}

export default FileDetail;